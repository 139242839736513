import React from "react";
import { ImLinkedin } from "react-icons/im";
import { BsGithub } from "react-icons/bs";
import { FaDribbble } from "react-icons/fa";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://linkedin.com" rel="noopener noreferrer" target="_blank">
        <ImLinkedin />
      </a>
      <a href="https://github.com" rel="noopener noreferrer" target="_blank">
        <BsGithub />
      </a>
      <a href="https://dribbble.com" rel="noopener noreferrer" target="_blank">
        <FaDribbble />
      </a>
    </div>
  );
};

export default HeaderSocials;
