import React from "react";
import FooterItems from "./FooterItems";
// import Title from "../mis/Title";
import "./Footer.css";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";

const Footer = () => {
  return (
    <footer>
      {/* <Title smtitle="Good to See You" maintitle="Thank You"></Title> */}
      <a href="#" className="footer__logo">
        HUGH SCOTT
      </a>
      {/* ------------------------------------- */}
      {/* <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul> */}
      {/* ------------------------------------- */}
      <div className="footer__socials">
        <a href="https://facebook.com">
          <FaFacebookSquare />
        </a>
        <a href="https://instagram.com">
          <BsInstagram />
        </a>
        <a href="https://twitter.com">
          <FiTwitter />
        </a>
      </div>
      {/* ------------------------------------- */}
      <div className="footer__copyright">
        <small>&copy; Property of HUGH SCOTT. ALL rights reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
