import React from "react";
import "./Experience.css";
import Exarticle from "./exarticle/Exarticle";
import Title from "../mis/Title";

const Experience = () => {
  const ex = "Experienced";
  const skilled = "Skilled";
  const inter = "Intermediate";
  return (
    <section id="experience">
      <Title smtitle="Skills Garnered" maintitle="My Experience"></Title>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Fontend Dev</h3>
          <div className="experience__content">
            <Exarticle title="HTML" body={ex} />
            <Exarticle title="Tailwind CSS" body={skilled} />
            <Exarticle title="Bootstrap" body={ex} />
            <Exarticle title="React.js" body={ex} />
            <Exarticle title="Next.js" body={skilled} />
            <Exarticle title="Redux" body={skilled} />
          </div>
        </div>
        {/* END OF FONTEND */}
        <div className="experience__backend">
          <h3>Backend Dev</h3>
          <div className="experience__content">
            <Exarticle title="Node.js" body={ex} />
            <Exarticle title="Algorithms" body={skilled} />
            <Exarticle title="MongoDB" body={ex} />
            <Exarticle title="RESTful API's" body={ex} />
            <Exarticle title="SQL" body={ex} />
            <Exarticle title="Type/Javascript" body={skilled} />
          </div>
        </div>
        {/* END OF BACKEND */}
        <div className="experience__data">
          <h3>Data Science</h3>
          <div className="experience__content">
            <Exarticle title="Python" body={inter} />
            <Exarticle title="Data Visualization" body={inter} />
            <Exarticle title="NumPy" body={inter} />
            <Exarticle title="Advanced Statistics" body={inter} />
            <Exarticle title="Math.lib" body={inter} />
            <Exarticle title="Machine Learning" body={inter} />
          </div>
        </div>
        {/* END OF DATA SCIENCE */}
      </div>
    </section>
  );
};

export default Experience;
