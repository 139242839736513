import React from "react";
import "./Services.css";
import ServicesList from "./ServicesList";
import Title from "../mis/Title";

const Services = () => {
  const body = [
    {
      body: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
    },
    {
      service1: '',

    },
    {
      service1: 'Create data models to forecast outcomes',
      service2: 'Fraud Analysis and business risk',
      service3: 'Find patterns and trends in datasets to uncover insights',
      service4: 'Machine learning techniques to improve the quality of product offerings',

    }
  ];

  return (
    <section id="services">
      <Title smtitle="What I Offer" maintitle="Services"></Title>
      <div className="container services__container">
        {/* ------------------------ */}
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
          </ul>
        </article>
        {/* ------------------------ */}
        <article className="service">
          <div className="service__head">
            <h3>Fullstack Web Developer</h3>
          </div>
          <ul className="service__list">
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
            <ServicesList body={body[0].body} />
          </ul>
        </article>
        {/* ------------------------ */}
        <article className="service">
          <div className="service__head">
            <h3>Data Science</h3>
          </div>
          <ul className="service__list">
            <ServicesList body={body[2].service1} />
            <ServicesList body={body[2].service2} />
            <ServicesList body={body[2].service3} />
            <ServicesList body={body[2].service4} />
          </ul>
        </article>
        {/* ------------------------ */}
      </div>
    </section>
  );
};

export default Services;
