import React from "react";
import "./About.css";

const AboutItems = (props) => {
  return (
    <article className="about__card">
      {props.children}
      <h5 className="card__head">{props.title}</h5>
      <small>{props.body}</small>
    </article>
  );
};

export default AboutItems;
