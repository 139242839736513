import React from "react";
import "./Portfolio.css";
import Title from "../mis/Title";
import PortfolioItem from "./PortfolioItem";
import logo from "../../assets/logo.png";
// import IMG1 from "../../assets/portfolio1.jpg";
// import IMG2 from "../../assets/portfolio2.jpg";
// import IMG3 from "../../assets/portfolio3.jpg";
// import IMG4 from "../../assets/portfolio4.jpg";
// import IMG5 from "../../assets/portfolio5.png";
// import IMG6 from "../../assets/portfolio6.jpg";
import Elotree from "../../assets/Elotree-Fav.png";

//Dont use images in production

const Portfolio = () => {
  const data = [
    {
      id: 1,
      image: Elotree,
      img_title: "Elotree",
      title: "Software Developer on Elotree Agency Management Platform",
      link: "https://elotree.com/",
      demo: "https://elotree.com/features/",
    },
    {
      id: 2,
      image: logo,
      img_title: "TypeSnake",
      title: "TypeSnake - A type safe superset programming language of Python",
      link: "https://github.com/HughScott2002/CIT-4004-APL-Group-Project",
      demo: "https://apl-cit-4004-web-ui.vercel.app",
    },
    // {
    //   id: 3,
    //   image: IMG3,
    //   img_title: "Cryptop Currency Dashboard & Financial Visualization",
    //   title: "Lorem ipsum, dolor sit amet",
    //   link: "https://github.com",
    //   demo: "https://github.com",
    // },
    // {
    //   id: 4,
    //   image: IMG4,
    //   img_title: "Cryptop Currency Dashboard & Financial Visualization",
    //   title: "Lorem ipsum, dolor sit amet",
    //   link: "https://github.com",
    //   demo: "https://github.com",
    // },
    // {
    //   id: 5,
    //   image: IMG5,
    //   img_title: "Cryptop Currency Dashboard & Financial Visualization",
    //   title: "Lorem ipsum, dolor sit amet",
    //   link: "https://github.com",
    //   demo: "https://github.com",
    // },
    // {
    //   id: 6,
    //   image: IMG6,
    //   img_title: "Cryptop Currency Dashboard & Financial Visualization",
    //   title: "Lorem ipsum, dolor sit amet",

    //   link: "https://github.com",
    //   demo: "https://github.com",
    // },
  ];
  return (
    <section id="portfolio">
      <Title smtitle="Projects I've Done" maintitle="Portfolio"></Title>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, img_title, link, demo }) => {
          return (
            <>
              <PortfolioItem
                key={id}
                img={image}
                img_title={img_title}
                title={title}
                link_title="Github"
                link={link}
                demo_link={demo}
              ></PortfolioItem>
            </>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
