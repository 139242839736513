import React from "react";
import "./Contact.css";

const ContactItems = (props) => {
  return (
    <>
      <article className="contact__option">
        {props.children}
        <h4>{props.type_head}</h4>
        <h5>{props.type_value}</h5>
        <a href={props.msg_link} rel="noopener noreferrer" target="_blank">
          {props.msg}
        </a>
      </article>
    </>
  );
};

export default ContactItems;
