import React from "react";
import "./About.css";
import ME from "../../assets/me-about.jpg";
import { FaReact } from "react-icons/fa";
import { AiOutlineDatabase } from "react-icons/ai";
import { MdSyncProblem, MdOutlineSchool } from "react-icons/md";
import Title from "../mis/Title";
import AboutItems from "./AboutItems";

const About = () => {
  return (
    <section id="about">
      <Title smtitle="GET TO KNOW" maintitle="About Me"></Title>
      <div className="container about__container">
        <div className="about__me">
          <img src={ME} alt="About" className="about__me-image" />
        </div>

        <div className="about__content">
          <div className="about__cards">
            {/* FullStack */}
            <AboutItems
              title="FullStack Web Development"
              body="Build Responsive and scalable web apps"
            >
              <FaReact className="about__icon" />
            </AboutItems>
            {/* Data Science */}
            <AboutItems
              title="Data Science"
              body="Able to build informative algorithmic models and visualize data"
            >
              <AiOutlineDatabase className="about__icon" />
            </AboutItems>
            {/* Problem Solving */}
            <AboutItems
              title="Problem Solving"
              body=" Knowledge of ideal data structures and algorithms for language
              independent performance. Sharp analytical skills and willingness
              to explore for new solutions"
            >
              <MdSyncProblem className="about__icon" />
            </AboutItems>
            {/* Earn to learn/Education */}
            <AboutItems
              title="Education"
              body="Forth year student at the University of Technology, Jamaica Majoring in Computer Science. Understanding of Object Oriented concepts and functional
              programming alongside a thirst for knowledge and growth"
            >
              <MdOutlineSchool className="about__icon" />
            </AboutItems>
            {/* <AboutItems
              title="Eager To Learn"
              body="Understanding of Object Oriented concepts and functional
              programing alongside a thirst for Knowledge and growth"
            >
              <MdOutlineSchool className="about__icon" />
            </AboutItems> */}
          </div>
          <p>
            Highly motivated and detail-oriented professional experience in data
            analysis and visualization. Skilled in using Python and R to analyze
            large datasets and create visualizations alongside having a expert
            grasp on web technology's and standards that communicate complex
            information in an easy-to-understand format. Strong communication
            skills with experience presenting findings to both technical and
            non-technical audiences. Passionate about using data to drive
            business decisions and improve processes.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
