import React from "react";
import "./Testimonials.css";
import Title from "../mis/Title";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

//import "./styles.css";

// import required modules
import { Navigation, Pagination, Keyboard } from "swiper";

const Testimonials = () => {
  const img_array = [
    {
      id: 1,
      name: "Jenna Mars",
      img: AVTR1,
      img_alt: "",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione nulla natus consequatur velit provident rerum laudantium consequuntur sed numquam reiciendis vitae fuga nemo sit repellat at quis, magnam quos repudiandae.",
    },
    {
      id: 2,
      name: "Ken Barbaido",
      img: AVTR2,
      img_alt: "",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione nulla natus consequatur velit provident rerum laudantium consequuntur sed numquam reiciendis vitae fuga nemo sit repellat at quis, magnam quos repudiandae.",
    },
    {
      id: 3,
      name: "Jared Block",
      img: AVTR3,
      img_alt: "",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione nulla natus consequatur velit provident rerum laudantium consequuntur sed numquam reiciendis vitae fuga nemo sit repellat at quis, magnam quos repudiandae.",
    },
    {
      id: 4,
      name: "Farah Day",
      img: AVTR4,
      img_alt: "",
      review:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione nulla natus consequatur velit provident rerum laudantium consequuntur sed numquam reiciendis vitae fuga nemo sit repellat at quis, magnam quos repudiandae.",
    },
  ];
  return (
    <section id="testimonials">
      <Title maintitle="Testimonials" smtitle="Refrences"></Title>
      <Swiper
        cssMode={true}
        navigation={true}
        spaceBetween={40}
        pagination={{
          dynamicBullets: true,
        }}
        keyboard={true}
        modules={[Navigation, Pagination, Keyboard]}
        className="container testimonials__container"
      >
        {img_array.map(({ id, name, img, img_alt, review }) => {
          return (
            <SwiperSlide>
              <article key={id} className="testimonial">
                <div className="client__avatar">
                  <img src={img} alt={img_alt} />
                </div>
                <h5 className="client__name">{name}</h5>
                <small className="client__review">{review}</small>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
