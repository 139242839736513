import React from "react";

const Title = (props) => {
  return (
    <>
      <h5>{props.smtitle}</h5>
      <h2>{props.maintitle}</h2>
    </>
  );
};

export default Title;
