import React from "react";
import "../Experience.css";
import { BsFillPatchCheckFill } from "react-icons/bs";

const Exarticle = (props) => {
  return (
    <article className="experience__details">
      <li>
        <BsFillPatchCheckFill className="experience__details-icon" />
      </li>
      <div>
        <h4>{props.title}</h4>
        <small className="text-light">{props.body}</small>
      </div>
    </article>
  );
};

export default Exarticle;
