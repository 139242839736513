import React from "react";
import "./Portfolio.css";

const PortfolioItem = (props) => {
  return (
    <article className="portfolio__item">
      <div className="portfolio__item-img">
        <img src={props.img} alt={props.img_title} />
      </div>
      <h3>{props.title}</h3>
      <div className="portfolio__item_cta">
        <a
          href={props.link}
          className="btn"
          rel="noopener noreferrer"
          target="_blank"
        >
          {props.link_title}
        </a>
        <a
          href={props.demo_link}
          className="btn btn-primary"
          rel="noopener noreferrer"
          target="_blank"
        >
          Live Demo
        </a>
      </div>
    </article>
  );
};

export default PortfolioItem;
