import React from "react";
// import CV from "../../assets/cv.pdf";
import CV from "../../assets/Hugh_Scott_CV.pdf"; //change this

const CTA = () => {
  return (
    <div className="cta">
      <a href={CV} download className="btn">
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
};

export default CTA;
