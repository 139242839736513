import React, { useRef } from "react";
import "./Contact.css";
import Title from "../mis/Title";
import ContactItems from "./ContactItems";
import { BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { HiOutlineMailOpen } from "react-icons/hi";
import emailjs from "@emailjs/browser";
// import process from "process";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.SERVICE_ID}`,
        `${process.env.TEMP_ID}'`,
        form.current,
        `${process.env.PUBLIC_KEY}`
      )
      // .sendForm(
      //   "service_sf98cyr",
      //   "template_axf0jdh",
      //   form.current,
      //   "CquXwYndWq_ANKl32"
      // )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    // .then(
    //   (result) => {
    //     console.log(result.text);
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );
    e.target.reset();
  };
  return (
    <section id="contact">
      <Title smtitle="Get in touch" maintitle="Contact Me" />
      <div className="container contact__container">
        <div className="contact__options">
          <ContactItems
            type_head="Gmail"
            type_value="hughrscott2002@gmail.com"
            msg_link="mailto:hughrscott2002@gmail.com"
            msg="Send an email"
          >
            <HiOutlineMailOpen className="contact__option-icon" />
          </ContactItems>
          <ContactItems
            type_head="LinkedIn"
            type_value="LinkedIn/Hugh Scott"
            msg_link="https://www.linkedin.com/"
            msg="Send a message"
          >
            <BsLinkedin className="contact__option-icon" />
          </ContactItems>
          <ContactItems
            type_head="WhatsApp"
            type_value="+1-(876)-568-4318"
            msg_link="https://api.whatsapp.com/send?phone=18765684318 "
            msg="Send a message"
          >
            <BsWhatsapp className="contact__option-icon" />
          </ContactItems>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Name / Organization"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="youremail@email.com"
            required
          />
          <textarea
            name="message"
            rows="10"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send a Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
