import React from "react";
import { BsCheck2All } from "react-icons/bs";
import "./Services.css";

const ServicesList = (props) => {
  return (
    <li>
      <li>
        <BsCheck2All className="service__list-icon" />
      </li>
      <p>{props.body}</p>
    </li>
  );
};

export default ServicesList;
